<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Interne update" overview_route="/internal" />

    <div class="mt-10 sm:mt-0" v-if="newsItem">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">{{newsItem.title}}</h3>
            <p class="mt-1 text-sm text-gray-600">
              {{moment(newsItem.createdAt).fromNow()}}
            </p>
          </div>
        </div>

        <div class="mt-5 md:mt-0 md:col-span-2" v-html="newsItem.body">
          
          
        </div>
          
      </div>
    </div>

  </main>
</template>

<script>
import InternalNewsService from '../../../services/admin/internal/service';
import moment from 'moment';
moment.locale('nl');

export default {
  name: 'InternalUpdateShow',
  data() {
    return {
      newsItem: null,
      loading: true,
      currentLocale: 'en',
      currentId: this.$route.params.id
    }
  },
  created: function () {
    this.moment = moment;
  },
  methods: {
    async fetchData() {
      this.loading = true;
      return InternalNewsService.getNewsItem(this.currentId).then(res => {
        if (res.status !== 200) {
          const error = new Error(res.statusText);
          throw error;
        }
        return res.data;
      })
      .then(json => {
        // set the response data
        this.newsItem = json;
        
      })
      .catch(err => {
        this.error = err;
        // In case a custom JSON error response was provided
        if (err.json) {
          return err.json.then(json => {
            // set the JSON response message
            this.error.message = json.message;
          });
        }
      })
      .then(() => {
        this.loading = false;
      });
    
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>
